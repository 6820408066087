<template>
  <div class="cover-home">
    <el-dialog title="选择封面" :visible.sync="dialogVisible" width="720px" :show-close="false" @close="dialogClosed">
      <div class="cover-image">
        <div
          :class="{coverBlock:cover.id==activeId?true:false}"
          @click="handleCover(cover)"
          v-for="cover in covers"
          :key="cover.id"
        >
          <span class="demonstration"></span>
          <el-image style="width: 100px; height: 100px" :src="cover.url" fit="cover"></el-image>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-size="queryInfo.pageSize"
          layout="prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCoverDialog">取 消</el-button>
        <el-button type="primary" @click="selectCover">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "selectCover",
  props: {
      dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      queryInfo: {
        pageNum: 1,
        pageSize: 18
      },
      covers: [],
      total: 0,
      activeId: 0,
      url: ""
    };
  },
  mounted() {
    this.getCover();
  },
  methods: {
    async getCover() {
      // 加载文章信息
      const { data: res } = await this.$api.getCover(this.queryInfo);
      this.covers = res.data.records;
      this.total = res.data.total;
    },
    dialogClosed() {
      this.url = "";
      this.closeCoverDialog()
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getCover();
    },
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent;
      this.getCover();
    },
    handleCover(cover) {
      this.activeId = cover.id;
      this.url = cover.url;
    },
    selectCover() {
      this.$emit("selectCover", this.url);
    },
    closeCoverDialog(){
        this.$emit("closeCoverDialog");
    }
  }
};
</script>

<style lang="less" scoped>
// 水平分布，换行
.cover-image {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  .coverBlock {
    border: 3px rgb(43, 53, 196) solid;
  }
}
.sure-btn {
  display: flex;
  justify-content: center;
}
.page {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>