<template>
  <div class="edt-home">
    <!-- 输入框 -->
    <div class="title-bar">
      <div class="title">
        <el-input maxlength="50" show-word-limit v-model="article.title" placeholder="请输入标题"></el-input>
      </div>
      <div>
        <!-- 弹出对话框 -->
        <router-link target="_blank" :to="{name:'articleInfo',params:{articleId: 1}}">
          <el-button type="warning">创作规范</el-button>
        </router-link>
      </div>
      <div>
        <el-dropdown size="medium" type="primary">
          <el-button type="primary" @click="showDialog">
            文章分类
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown"></el-dropdown-menu>
        </el-dropdown>
      </div>
      <div>
        <el-dropdown @command="handleCommand" size="medium">
          <el-button type="primary">
            设置封面
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="showCoverDialog">选择封面</el-dropdown-item>
            <el-dropdown-item command="uploadCover">上传封面</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-upload
          class="upload-demo"
          action
          accept="image/jpeg, image/png, image/jpg"
          :on-remove="handleRemove"
          :http-request="uploadCover"
          :file-list="fileList"
          list-type="picture"
          :multiple="false"
          :limit="1"
        >
          <el-button
            v-show="false"
            ref="uploadCover"
            style="height:100%;"
            size="medium"
            type="primary"
          >上传封面</el-button>
        </el-upload>
        <selectCover
          @selectCover="selectCover"
          @closeCoverDialog="closeCoverDialog"
          :dialogVisible.sync="showCoverDialog"
        ></selectCover>
      </div>
      <div>
        <el-dropdown @command="saveArticle" size="medium">
          <el-button type="primary">
            发布
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">保存为草稿</el-dropdown-item>
            <el-dropdown-item command="2">发布文章</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-row style="height: calc(100vh - 140px);">
      <!-- 编辑器 :toolbars="toolbars" -->
      <mavon-editor
        v-model="article.content"
        style="height: 100%;"
        ref="md"
        placeholder="声明:为了更好的管理本站，请遵守本站规约；不得发表违反本站规约内容！！详情请看创作规范"
        @save="saveArticle(2)"
        @imgAdd="$imgAdd"
        @imgDel="$imgDel"
        fontSize="16px"
      ></mavon-editor>
      <!-- 对话框 -->
      <el-dialog :visible.sync="dialogVisible" width="30%">
        <el-divider content-position="left">摘要</el-divider>
        <!-- 文本框 -->
        <el-input type="textarea" v-model="article.digest" rows="5" maxlength="150" show-word-limit></el-input>
        <!-- 文章教程 -->
        <el-divider v-if="this.permission('ROLE_ADMIN')" content-position="left">教程</el-divider>
        <tree
          childrenName="children"
          value="cpath"
          nodeId="path"
          nodeName="categoryName"
          :treeData="categoryTree"
          :fromData="article"
          v-permission="['ROLE_ADMIN']"
        ></tree>
        <el-divider v-if="this.permission('ROLE_ADMIN')" content-position="left">排序</el-divider>
        <el-input-number
          v-permission="['ROLE_ADMIN']"
          size="small"
          v-model="article.sort"
          controls-position="right"
          :min="1"
          :max="100"
          placeholder="教程文章排序"
        ></el-input-number>
        <el-divider content-position="left">标签</el-divider>
        <!-- 文章标签 -->
        <selectTag :tagList="tagList" :tagId="''+article.tagId"  @selectTag="(id)=>{article.tagId=id}"></selectTag>
        <el-divider content-position="left">分类</el-divider>
        <!-- 文章分类 -->
        <selectTag :tagList="classifyList" :tagId="''+article.classifyId" @selectTag="(id)=>{article.classifyId=id}"></selectTag>
        <!-- 点击按钮 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
import { encrypt } from "@/utils/encoder.js";
import selectCover from "@/components/selectCover/selectCover";
import selectTag from "@/components/selectTag/selectTag";
import tree from "@/components/tree/tree";
export default {
  name: "ArticleEdit",
  components: {
    selectCover,
    selectTag,
    tree
  },
  data() {
    return {
      article: {
        id: "",
        digest: "",
        content: "",
        title: "",
        cover: "",
        author: "",
        tagId: "",
        classifyId: "",
        cpath: ""
      },
      fileList: [],
      dialogVisible: false,
      categoryTree: [],
      defaultProps: {
        // 树节点
        children: "children",
        label: "categoryName"
      },
      tagList: [],
      classifyList: [],
      showCoverDialog: false,
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: false, // 标记
        superscript: false, // 上角标
        subscript: false, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: false, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: false, // 导航目录
        /* 2.1.8 */
        alignleft: false, // 左对齐
        aligncenter: false, // 居中
        alignright: false, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true // 预览
      }
    };
  },
  computed: {
    username() {
      return this.$store.state.permission.user.username;
    }
  },
  mounted() {
    // 修改文章过来的路由参数
    var articleId = this.$route.params.articleId;
    if (articleId) {
      this.getArticleInfoById(articleId);
    }
    this.getCategory();
    this.getArticleTag();
    this.getArticleClassify();
  },
  methods: {
    showDialog() {
      if (!this.article.digest && this.article.content) {
        this.article.digest = this.article.content.substr(0, 100);
      }
      this.dialogVisible = true;
    },
    // 保存文章
    saveArticle(status) {
      if (!this.article.content || this.article.content.length < 300) {
        this.$alert("文章字数少于300字，不准水文呀！", "提示", {
          confirmButtonText: "确定",
          callback: action => {}
        });
        return;
      }
      if (!this.article.title || !this.article.classifyId) {
        this.$alert("标题和分类不能缺省哟", "提示", {
          confirmButtonText: "确定",
          callback: action => {}
        });
        return;
      }
      let tip = "是否保存并发布文章?";
      if (status == 1) {
        tip = "是否保存为草稿?";
      }
      this.article.status = status;
      // value 是 md，render 是 html
      this.$confirm(tip, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          // 文章修改
          if (this.article.id) {
            const { data: res } = await this.$api.updateArticleInfo(
              this.article.id,
              this.article
            );
          } else {
            // 新增文章
            this.article.author = this.username;
            const { data: res } = await this.$api.addArticleInfo(this.article);
          }
          setTimeout(() => {
            this.$router.push({
              name: "homepage",
              params: { username: encrypt(this.username) }
            });
          }, 500);
        })
        .catch(() => {
          this.$message({ type: "info", message: "已取消发布" });
        });
    },
    async getArticleInfoById(id) {
      // 加载文章信息
      const { data: res } = await this.$api.getArticleInfoById(id);
      this.article = res.data;
    },
    // 获取文章教程分类
    async getCategory() {
      const { data: res } = await this.$api.getCategoryTree();
      let arr = [{ id: -1, categoryName: "顶级类目", children: res.data }];
      this.categoryTree = arr;
    },
    // 文章标签
    async getArticleTag() {
      const { data: res } = await this.$api.getArticleTag({
        username: this.username
      });
      this.tagList = res.data;
    },
    // 文章分类
    async getArticleClassify() {
      const { data: res } = await this.$api.getArticleClassify();
      res.data.forEach(element => {
        element.tagName = element.classifyName;
      });
      this.classifyList = res.data;
    },
    // 将图片上传到服务器
    async $imgAdd(pos, $file) {
      let formdata = new FormData();
      formdata.append("file", $file);
      const { data: res } = await this.$api.uploadImg(formdata);
      this.$refs.md.$img2Url(pos, res.data);
    },
    async $imgDel(pos) {
      let path = pos[0].replace("https://img.zszxz.com/images/", "");
      const { data: res } = await this.$api.deleteImg(path);
    },
    async handleRemove(file, fileList) {
      let path = this.article.cover.replace(
        "https://img.zszxz.com/images/",
        ""
      );
      const { data: res } = await this.$api.deleteImg(path);
    },
    async uploadCover(content) {
      //验证图片格式大小信息
      const isLt1M = content.file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message({ type: "error", message: "上传图片大小不能超过 1MB!" });
        this.fileList = [];
        return;
      }
      const formData = new FormData();
      formData.append("file", content.file);
      const { data: res } = await this.$api.uploadImg(formData);
      this.article.cover = res.data;
      this.fileList[0] = {
        name: content.file.name,
        url: this.article.cove
      };
    },
    // 触发图片上传
    handleCommand(command) {
      if (command == "showCoverDialog") {
        this.showCoverDialog = true;
      } else {
        this.$refs[`${command}`].$el.click();
      }
    },
    // 选择封面
    selectCover(url) {
      this.article.cover = url;
      this.showCoverDialog = false;
    },
    closeCoverDialog() {
      this.showCoverDialog = false;
    }
  }
};
</script>

<style scoped >
.edt-home {
  width: 1500px;
}
.title-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.title {
  width: 900px;
}
.upload-demo {
  position: absolute;
  z-index: 2000;
  width: 120px;
  height: 40px;
}
</style>