<template>
  <div class="tag-home">
    <el-tag
          class="tag"  
          v-for="tag in tagList"
          :key="tag.id"
          :effect="tag.id==tagId?'dark':'plain'"
          @click="selectTag(tag.id)"
        >{{ tag.tagName }}</el-tag>
  </div>
</template>

<script>
export default {
  name: "selectTag",
  props: {
      tagList: {
      type: Array,
    },
    tagId: {
      type: String,
      default: 0
    }
  },
  data() {
    return {
      
    };
  },
  mounted() {
  },
  methods: {
    selectTag(id) {
      this.tagId = id
      this.$emit("selectTag", id);
    },
  }
};
</script>

<style lang="less" scoped>
// 水平分布，换行
.tag-home {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  .tag{
      margin: 5px 0px;
  }
}

</style>